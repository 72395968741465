import React from "react"
import BreadCrumbs from "./BreadCrumbs"
import Items from "./Items"
import Item from "./Item"
import Logo from "../images/logo.svg"
import LoadingIcon from "../images/loading.svg"
import axios from "axios"

const API_ENDPOINT = "//api.magerepo.com"

export default class Application extends React.Component {

	constructor ( props ) {
		super ( props )
		this.state = {
			error: this.props.error || false,
			files: [],
			folders: []
		}
	}

	componentDidMount () {
		let that = this
		if ( this.props.folder && this.props.staticData ) {
			this.setState ({ folders: this.props.staticData })
		}
		else {
			let pathName = window.location.pathname.replace ( /^\//m, "" )
			axios.get (`${API_ENDPOINT}/metadata/${pathName}`)
				.then ( response => {
					let object = {}
					if ( that.props.folder ) {
						object.folders = response.data
							.map ( folder => {
								return folder [ that.props.nameLabel ]
							})
							.filter ( ( value, index, self ) => {
								return self.indexOf ( value ) === index
							})
					}
					else {
						object.files = response.data
					}
					that.setState ( object )
				})
				.catch ( error => this.setState ({ error: true }) )
		}
	}

	render () {
		let isError = this.state.error !== false ||
					  this.state.folders.length + this.state.files.length < 1
		return <div className="application" >
			<header>
				<div className="container" >
					<img src={ Logo } onClick={ () => window.location = "/" } />
					<span>
						MageRepo
					</span>
					<div className="links" >
						<a target="_blank" href="https://learn.jetrails.com/article/download-magento-assets-using-magerepo#using-our-restful-api" >API</a>
						<a target="_blank" href="https://github.com/magerepo/magerepo-cli" >CLI</a>
					</div>
					{ this.state.error ? "" : <BreadCrumbs hide={isError} /> }
				</div>
			</header>
			<Items hide={this.state.error} folder={this.state.folders.length > 0} >
				{(() => {
					if ( isError ) {
						return <h1>Could not find what you are looking for!</h1>
					}
					else if ( this.state.folders.length > 0 ) {
						return this.state.folders.map ( folder => {
							let link = `${window.location.href.replace ( /\/+$/, "" )}/${folder}`
							return <Item
								key={folder}
								name={folder}
								size="—"
								date="—"
								folder={true}
								link={link}
							/>
						})
					}
					else if ( this.state.files.length > 0 ) {
						let that = this
						return this.state.files.map ( file => {
							let link = API_ENDPOINT + file.file_download_link
							return <Item
								key={file [ that.props.nameLabel ]}
								name={file [ that.props.nameLabel ]}
								size={file [ that.props.sizeLabel ]}
								date={file [ that.props.dateLabel ]}
								folder={false}
								link={link}
							/>
						})
					}
					else {
						return <img className="error" src={LoadingIcon} />
					}
				}) ()}
			</Items>
		</div>
	}

}
