import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Route, IndexRoute, Switch } from 'react-router-dom'
import Application from "./components/Application"

ReactDOM.render (
	<BrowserRouter>
		<Switch>
			<Route
				exact
				path="/"
				render={() => <Application
					folder={true}
					staticData={["release","patch"]}
				/>}
			/>
			<Route
				exact
				path="/:folder(release|patch)"
				render={() => <Application
					folder={true}
					staticData={["community","enterprise"]}
				/>}
			/>
			<Route
				exact
				path="/release/:edition(community|enterprise)"
				render={() => <Application
					folder={false}
					nameLabel="file_name"
					sizeLabel="file_size_auto"
					dateLabel="info_date"
 				/>}
			/>
			<Route
				exact
				path="/patch/:edition(community|enterprise)"
				render={() => <Application
					folder={true}
					nameLabel="info_release"
				/>}
			/>
			<Route
				exact
				path="/patch/:edition(community|enterprise)/:release"
				render={() => <Application
					folder={false}
					nameLabel="file_name"
					sizeLabel="file_size_auto"
					dateLabel="info_date"
				/>}
			/>
			<Route render={() => <Application error={true} />} />
		</Switch>
	</BrowserRouter>,
	document.getElementById('main')
)
