import React from "react"
import copy from "copy-to-clipboard"

export default class Item extends React.Component {

	constructor ( props ) {
		super ( props )
		this.state = {
			copied: false,
		}
	}

	render () {
		const { folder, name, size, date, link } = this.props
		const { copied } = this.state
		return <div
			className={`item ${folder ? "folder" : ""}`}
			onClick={folder ? () => window.location = link : null} >
			<div className={`icon ${folder ? "folder" : ""}`} ></div>
			<div className="name" >{name}</div>
			{!folder && <div className="size" >{size}</div>}
			{!folder && <div className="date" >{date}</div>}
			{!folder && <div className="download" onClick={() => window.open ( link, "_blank" )} >Download</div>}
			{
				!folder && <div
					className="copy"
					onClick={() => this.setState ({ copied: true }, () => {
						copy ( link )
						setTimeout ( () => this.setState ({ copied: false }), 1000 )
					})} >
					{copied ? "Copied!" : "Copy"}
				</div>
			}
		</div>
	}

}
