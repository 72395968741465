import React from "react"
import SeparatorIcon from "../images/separator.svg"

export default class BreadCrumbs extends React.Component {

	componentDidMount () {
		document.title = window.location.pathname
			.replace ( /^\/+|\/+$/g, "" )
			.replace ( /\/+/g, "\/" )
			.split ("/")
			.join (" | ")
		document.title = `MageRepo | ${document.title}`.replace ( / \| $/, "" )
	}

	render () {
		let that = this
		return <div className="bread-crumbs" >{
			(() => {
				if ( !!!that.props.hide ) {
					return window.location.pathname
						.replace ( /^\/+|\/+$/g, "" )
						.replace ( /\/+/g, "\/" )
						.split ("/")
						.reduce ( ( accum, value, index ) => {
							return accum.concat ([{
								label: value,
								href: `${accum [ index ].href}/${value}`
									.replace ( /\/+/g, "\/" )
							}])
						}, [{ label: "trunk", href: "/" }] )
						.filter ( i => i.label != "" )
						.map ( ( v, i, a ) => {
							if ( a.length - 1 == i ) {
								<a key={v.label} >{v.label}</a>
							}
							return <a key={v.label} href={v.href} >{v.label}</a>
						})
						.reduce ( ( accum, value, index ) => {
							if ( index < 1 ) {
								return accum.concat ([ value ])
							}
							return accum.concat ([
								<img key={index} src={SeparatorIcon} />,
								value
							])
						}, [] )
				}
			}) ()
		}</div>
	}

}
