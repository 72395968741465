import React from "react"

export default class Items extends React.Component {

	render () {
		const { hide, children, folder } = this.props
		return <div className="items" >
			<div className={`container ${hide ? "hide" : ""}`} >
				<div className="header" >
					<div className="name" >Name</div>
					{ !folder && <div className="size" >Size</div>}
					{ !folder && <div className="date" >Date</div>}
					{ !folder && <div className="download" ></div>}
					{ !folder && <div className="copy" ></div>}
				</div>
				{children}
			</div>
		</div>
	}

}
